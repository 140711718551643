.cards {
  overflow-x: hidden;
  padding: 4rem;
  background: #424b57; /*#454746;*/
}

h1 {
  text-align: center;
  color: white;
  padding: 3rem;
}

/* .irrig {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 1.6rem;
} */

.cards__container {
  display: flex;
  flex-flow: column;
  align-items: center;
  /* max-width: 1120px; */
  width: 100%;
  margin: 0 auto;
}

.cards__wrapper {
  position: relative;
  margin: 2rem;
  /* margin: 50px 0 45px; */
}

.cards__items {
  margin-bottom: 2rem;
}

.cards__item {
  display: flex;
  flex: 1;
  margin: 0 0.6rem;
  border-radius: 10px;
  /* add */
  /* background: rgb(190, 183, 183); */
  background: #d3d0d0;
}

.cards__item__link {
  display: flex;
  flex-flow: column;
  width: 100%;
  box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
  -webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
  border-radius: 10px;
  overflow: hidden;
  text-decoration: none;
}

.cards__item__pic-wrap {
  position: relative;
  width: 100%;
  padding-top: 79%;
  overflow: hidden;
}

.fade-img {
  animation-name: fade-img;
  animation-duration: 2s;
}

.cards__item__pic-wrap::after {
  content: attr(data-category);
  position: absolute;
  bottom: 0;
  margin-left: 10px;
  padding: 6px 8px;
  max-width: calc((100%) - 60px);
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  background-color: #1f98f4;
  box-sizing: border-box;
}

.cards__item__img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  object-fit: cover;
  transition: all 0.2s linear;
}

.cards__item__img:hover {
  transform: scale(1.1);
}

.cards__item__info {
  padding: 20px 30px 30px;
}

.cards__item__text {
  color: black;
  font-size: 16px;
  line-height: 20px;
}
.cards__items {
  display: flex;
}

/* dhajkshfsakhf */
.cards__container,
.cards__wrapper {
  margin-bottom: 0;
  padding-bottom: 0;
}

@media screen and (max-width: 768px) {
  .cards {
    width: 100%;
    padding: 20px;
  }
  .cards__wrapper {
    width: 100%;
  }

  .cards__items {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
  }
  .cards__item {
    padding-bottom: 10px;
  }
}
