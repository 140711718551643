* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "PT Sans", sans-serif;
}

.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 70vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url("../public/images/cc.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  display: flex;
  flex-direction: column;
}
.services h3,
.products h3 {
  display: block;
  margin: 3rem 6rem;
  font-size: 40px;
  margin-left: 4rem;
}
.products h3 {
  padding-top: 0;
}
.products h1,
.services h1 {
  font-size: 80px;
  /* padding-bottom: 2rem;
  margin-top: 2rem; */
}
.services h1,
.services h3 {
  color: #fff;
}

.products {
  background-image: url("../public/images/v2.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 900px) {
  .products,
  .services {
    font-size: 40px;
    margin-top: -50px;
    /* margin-bottom: -60px; */
  }
  .services h1,
  .services h3,
  .products h1,
  .products h3 {
    color: #fff;
    font-size: 40px;
    margin-left: 10px;
  }
  .services h3,
  .products h3 {
    font-size: 20px;
    padding: 0;
    margin: 2rem;
  }
}
