.navbar {
  /* overflow-x: hidden; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 80px;
  /* border-radius: 15px; */
  background: #2c4858;
  position: sticky;
  top: 0;
  z-index: 400;
}

.nbar-logo {
  display: flex;
}

.navbar img {
  margin-left: 20px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
}
.navbar-logo {
  margin-top: 16px;
  font-size: 2rem;
  cursor: pointer;
  color: #fff;
}
.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  align-items: center;
}
.nav-menu li {
  color: #fff;
  padding-bottom: 20px;
}
.nav-links {
  text-decoration: none;
  font-size: 1.2rem;
  font-weight: 600;
  padding: 0.8rem 1rem;
  white-space: nowrap;
  /* color: white; */
}
.nav-links:hover {
  background: #50b6ad;
  border-radius: 5px;
  color: white;
  transition: all 0.2s ease-in-out;
}
.icon {
  display: none;
}
.icons {
  padding-right: 10px;
  font-size: 2rem;
  padding-top: 15px;
}

@media screen and (max-width: 1000px) {
  .navbar {
    z-index: 1000;
    height: 70px;
    padding: 10px 20px 10px 5px;
  }
  .navbar-logo {
    padding-bottom: 20px;
  }
  .nav-menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    background: #667574;
    width: 100%;
    height: auto;
    position: absolute;
    top: 0;
    left: -100%;
    opacity: 0;
    align-items: stretch;
    padding: 10px 0 30px 0;
    margin: 0;
    margin-top: 70px;
    /* z-index: -1; */
  }
  .nav-menu.active {
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 100;
  }
  .nav-links {
    display: block;
    width: 100%;
    font-size: 1.2rem;
    padding: 1rem 0;
    text-align: center;
  }
  .nav-links:hover {
    background: #4b5756;
    transition: none;
  }
  .icon {
    display: block;
  }
  .barIcons {
    font-size: 1.4rem;
    color: #fff;
  }
  .nbar-logo h2 {
    font-size: 14px;
    width: 40px;
  }

  .navbar img {
    margin-left: 0;
    padding-top: 10px;
    padding-bottom: auto;
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
