.hero-container {
  overflow-x: hidden;
  /* background: url("../../public/images/h1.jpg") center center/cover no-repeat; */
  height: 70vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
  /* object-fit: contain; */
  /* transition: background-image 1s ease-in-out; */
  transition: all 1s ease;
  padding-left: 1rem;
}

.hero-container > h1 {
  color: #fff;
  font-size: 100px;
  margin-top: 130px;
}

.hero-container > h2 {
  color: #fff;
  font-size: 40px;
  margin-top: 20px;
}

.hero-container > p {
  margin-top: 20px;
  color: #fff;
  font-size: 32px;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

@media screen and (max-width: 960px) {
  .hero-container > h1 {
    font-size: 60px;
    margin-top: -150px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 50px;
    margin-top: 40px;
    padding-bottom: 10px;
  }

  .hero-container > p {
    font-size: 18px;
  }
  .hero-container h2 {
    font-size: 24px;
  }
}
