.ag-products {
  padding: 3rem 3rem;
  padding-top: 0;
  background: #424b57;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.ag-products img {
  width: 30%;
  height: 600px;
  padding: 10px;
  border-radius: 20px;
  margin-right: 20px;
  transition: box-shadow 0.3s ease;
}
.ag-content {
  padding-bottom: 2rem;
  text-align: center;
  color: white;
}
.ag-content p {
  padding-top: 1rem;
}

.ag-images img:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

@media screen and (max-width: 868px) {
  .ag-products {
    padding: 10px;
  }
  .ag-images {
    display: flex;
    width: 100%;
  }
  .ag-products img {
    width: 32%;
    height: auto;
    padding: 10px;
    border-radius: 10px;
    margin-right: 0;
    transition: box-shadow 0.3s ease;
  }
  /* .ag-images img {
    width: 47%;
    height: auto;

  } */
}
