.service-container {
  overflow-x: hidden;
  padding: 4rem 6rem;
  background: rgb(186, 190, 194);
  text-align: center;
}
.service-container h1 {
  font-size: 3rem;
  color: black;
}
.service-container p {
  margin-top: 3rem;
  font-size: 20px;
  text-align: left;
}

.scard {
  margin-top: 0.4rem;
  display: flex;
  justify-content: space-between;
}
.service-card {
  width: 26%;
  height: auto;
  text-align: start;
  box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  cursor: pointer;
  padding: 1rem 0.6rem;
  transition: 0.3s ease-in-out;
  background: rgb(190, 192, 194);
}
.service-card ul {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 18px;
}

.image {
  overflow: hidden;
  border-radius: 4px;
  text-align: center;
}
.image img {
  width: 60%;
  height: 250px;
  border-radius: 24px;
}
.togta_images {
  display: flex;
  flex-direction: row;
}
.togta_images img {
  width: 23%;
  height: auto;
  margin: 1rem 1rem;
  border-radius: 8px;
}
.service-card:hover {
  /* transform: scale(1.1); */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}
.service-card h3 {
  padding: 1rem 0 0.3rem 0;
  text-align: center;
}

@media screen and (max-width: 850px) {
  .service-container {
    padding: 0 1rem;
  }
  .service-container h1 {
    font-size: 1.6rem;
    color: black;
  }
  .image img {
    width: 80%;
    height: 200px;
    border-radius: 12px;
  }
  .service-card ul {
    padding-left: 20px;
    padding-right: 10px;
    font-size: 16px;
  }
  .scard {
    flex-direction: column;
  }
  .service-card {
    width: 100%;
    margin-bottom: 2rem;
  }
}
