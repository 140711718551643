.feed-block {
  margin: 4rem 6rem;
  /* background-color: rgb(69, 71, 71); */
}

.feed-block h1 {
  padding-top: 30px;
  font-size: 3rem;
  color: black;
}

.newFeed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5rem;
}
.newFeed-reverse {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  margin-top: 4.5rem;
}

.content {
  width: 45%;
  text-align: start;
  font-size: 1.2rem;
  /* margin-left: 20px; */
}

.content h2 {
  padding-bottom: 1rem;
}
.content p {
  padding-top: 10px;
}

.image-block {
  /* display: flex;
  flex-direction: column;
  text-align: center; */
  position: relative;
  width: 50%;
  display: flex;
  justify-content: space-between;
  z-index: 99;
}

.image-block img {
  /* margin-bottom: 10px; */
  width: 49%;
  height: auto;
  object-fit: cover;
  border-radius: 6px;
  box-shadow: -1px 1px 62px -18px rgba(0, 0, 0, 0.19);
}

.image-block img:nth-child(2) {
  position: absolute;
  top: -10%;
  right: 0;
}
@media screen and (max-width: 850px) {
  .feed-block {
    margin: 2rem 1rem;
  }
  .feed-block h1 {
    font-size: 2.2rem;
  }
  .newFeed {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: space-between;
    margin-top: 2.5rem;
  }
  .newFeed-reverse {
    display: flex;
    flex-direction: column-reverse;
    margin-top: 4.5rem;
  }
  .content {
    width: 100%;
    font-size: 1rem;
  }
  .image-block {
    width: 100%;
    margin-bottom: 1rem;
  }
  /* .image-block img {
    width: 49%;
    height: auto;
  } */
}
